import React from 'react';
import GoogleMapReact from 'google-map-react';

export const Map = () => {
  return (
    <div style={{ height: '600px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API! }}
        options={{
          styles: [
            {
              featureType: 'all',
              elementType: 'labels',
              stylers: [
                {
                  visibility: '#on',
                },
              ],
            },
          ],
        }}
        defaultCenter={{ lat: 45.55, lng: 11.53 }}
        defaultZoom={12}
        yesIWantToUseGoogleMapApiInternals
      ></GoogleMapReact>
    </div>
  );
};
