import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

import Modal from 'react-bootstrap/Modal';
import { IoMdClose } from 'react-icons/io';
import { StaticImage } from 'gatsby-plugin-image';

export enum outcomeType {
  success = 'success',
  error = 'error',
}

const initialData = {
  from: '',
  subject: '',
  text: '',
};

export const Form = () => {
  const [data, setData] = useState(initialData);
  const [sending, setSending] = useState(false);
  const [outcome, setOutcome] = useState(outcomeType.success);
  const [popupOpen, setPopupOpen] = useState(false);

  const resetData = () => {
    setData(initialData);
  };

  const handleOutcome = (status: outcomeType) => {
    setOutcome(status);
    setPopupOpen(true);
  };

  const Poppy = () => {
    return (
      <Modal
        show={popupOpen}
        size="lg"
        popupOpen
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setPopupOpen(false)}
      >
        <Modal.Body>
          <div
            style={{ zIndex: 2 }}
            className="position-absolute end-0 me-4 mt-3"
          >
            <IoMdClose
              color="white"
              size={36}
              className="cursor-pointer"
              onClick={() => setPopupOpen(false)}
            />
          </div>
          {outcome === outcomeType.success ? (
            <StaticImage src="./images/inviato.png" quality={100} alt="mappa" />
          ) : (
            <StaticImage src="./images/fallito.png" quality={100} alt="mappa" />
          )}
        </Modal.Body>
      </Modal>
    );
  };

  const MAIL_API = 'https://vicenzaparcheggi-mailer.herokuapp.com/v1/sendmail';

  const send = (e: any) => {
    e.preventDefault();
    setSending(true);
    axios
      .post(MAIL_API, { data })
      .then((res) => {
        if (res.status === 200) {
          handleOutcome(outcomeType.success);
        }
      })
      .catch((error: any) => {
        handleOutcome(outcomeType.error);
        console.log(error);
      })
      .finally(() => {
        setSending(false);
        resetData();
      });
  };

  const handleDataChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <>
      {popupOpen && <Poppy />}
      <Send className="p-4">
        <h3 className="text-primary font-rubik">Scrivici</h3>
        <form onSubmit={(e) => send(e)}>
          <input
            type="text"
            name="subject"
            value={data.subject}
            placeholder="Il tuo Nome"
            required
            onChange={handleDataChange}
          ></input>
          <input
            type="email"
            name="from"
            value={data.from}
            placeholder="La tua Email"
            required
            onChange={handleDataChange}
          ></input>
          <textarea
            name="text"
            value={data.text}
            placeholder="Scrivici..."
            cols={350}
            rows={8}
            required
            onChange={handleDataChange}
          ></textarea>
          <div>
            <input style={{ width: 'auto' }} type="checkbox" required></input>
            <span className="ms-2 oxygen text-secondary">
              Ho preso visione dell'
              <a
                className="text-secondary privacy-link"
                href="/files/modulistica/INFORMATIVA TRATTAMENTO DATI/Informativa_trattamento_dati_personali_GPS2022.pdf"
                target="_blank"
                rel="noreferrer"
              >
                informativa Privacy Policy
              </a>
            </span>
          </div>
          {sending ? (
            <Button
              className="p-1 rounded bg-secondary text-white font-oxygen"
              type="button"
              disabled
            >
              Invio...
            </Button>
          ) : (
            <Button
              className="p-1 rounded bg-primary text-white font-oxygen"
              type="submit"
            >
              Invia
            </Button>
          )}
        </form>
      </Send>
    </>
  );
};

const Send = styled.div`
  border-radius: 10px;
  border: 8px solid #81a5ad;
  background-color: white;
`;

const Button = styled.button`
  margin-top: 15px;
  font-weight: 200;
  font-size: 20px;
  margin-bottom: 0px;
`;
