import React from 'react';
import { Layout } from '../components/layout';
import { Hero } from '@/components/layout/header/hero';
import { StaticImage } from 'gatsby-plugin-image';
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaTwitterSquare,
} from 'react-icons/fa';
import styled from 'styled-components';
import { Form } from '@/components/form';
import {
  EMAIL,
  FB_LINK,
  INSTAGRAM_LINK,
  PHONE,
  TWITTER_LINK,
} from '@/constants/links';
import { Map } from '@/components/maps/Map';

export default function Contatti() {
  const pageTitle = 'Contatti';

  const Banner = () => (
    <StaticImage
      src="../images/banners/contatti.png"
      alt={pageTitle}
      quality={100}
    />
  );

  const cardStyle =
    'p-3 pt-4 p-lg-4 rounded bg-primary text-white text-center text-xl-start';

  const TopBar = () => (
    <div style={{ maxWidth: '1600px' }} className="row mx-auto">
      <div className="col-12 col-xl-3 mt-2">
        <Card className={cardStyle}>
          <h2 className="h3 font-rubik">SEDI OPERATIVE</h2>
          <a
            className="text-white text-decoration-none"
            href="https://goo.gl/maps/gowoqjjHGi8Dxqcs5"
            target="_blank"
            rel="noreferrer"
          >
            <p>
              Ufficio Gestione Sosta presso Park Strasburgo
              <br />
              Via Strasburgo 4 – Vicenza
              <br />
              Giorni e orari di apertura al pubblico: lunedì – sabato 8:00 –
              12:00 | mercoledì 8:00 – 16:00
            </p>
          </a>
        </Card>
      </div>
      <div className="col-12 col-xl-3 mt-2">
        <Card className={cardStyle}>
          <h2 className="h3 font-rubik">SEDI OPERATIVE</h2>
          <a
            className="text-white text-decoration-none"
            href="https://goo.gl/maps/2K89Yq82UbtkGKAC7"
            target="_blank"
            rel="noreferrer"
          >
            <p>
              Ufficio Gestione Sosta presso Park Fogazzaro
              <br />
              Contrada Pedemuro S. Biagio – Vicenza
              <br />
              Giorni e orari di apertura al pubblico: lunedì – sabato 8:00 –
              12:00 | mercoledì 8:00 – 16:00
            </p>
          </a>
        </Card>
      </div>
      <div className="col-12 col-xl-3 mt-2">
        <Card className={cardStyle}>
          <h2 className="h3 font-rubik">Contatti</h2>
          <p className="font-oxygen">
            <b>Mail:</b>{' '}
            <a
              className="text-white text-decoration-none"
              href={`mailto:${EMAIL}`}
            >
              {EMAIL}
            </a>
            <br />
            <b>Tel:</b>&nbsp;
            <a
              className="text-white text-decoration-none"
              href={`tel:${PHONE[1].replaceAll(' ', '')}`}
            >
              {PHONE[1]}
            </a>
            &nbsp;|&nbsp;
            <a
              className="text-white text-decoration-none"
              href={`tel:${PHONE[0].replaceAll(' ', '')}`}
            >
              {PHONE[0]}
            </a>
            <br />
          </p>
        </Card>
      </div>
      <div className="col-12 col-xl-3 mt-2 mb-5">
        <Card className={cardStyle}>
          <h2 className="h3 font-rubik">Social</h2>
          <div className="pt-2">
            <a href={FB_LINK} target="_blank" rel="noreferrer">
              <FaFacebookSquare className="me-4" size={32} color="white" />
            </a>
            <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
              <FaInstagramSquare className="me-4" size={32} color="white" />
            </a>
            <a href={TWITTER_LINK} target="_blank" rel="noreferrer">
              <FaTwitterSquare size={32} color="white" />
            </a>
          </div>
        </Card>
      </div>
    </div>
  );

  return (
    <Layout title={pageTitle}>
      <Hero title={pageTitle} banner={<Banner />} />
      <div className="mt-5 mb-5 pt-4 pb-4">
        <TopBar />
        <div style={{ maxWidth: '1024px' }} className="container mx-auto">
          <div className="mt-2 mb-5 rounded">
            <Map />
          </div>
          <div className="row">
            <div className="col-12">
              <Form />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const Card = styled.div`
  min-height: 155px;
`;
